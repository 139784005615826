import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import { FormRendererDesignContext, FormRendererDesignDefaults } from '../../contexts/FormRendererDesignContext';
import FormRendererV2 from '../form/renderer/FormRendererV2';
import { PublicFormSectionWrapper, PublicFormActionWrapper, PublicFormSectionTitle } from './PublicFormView';
import { FormRendererType } from '../../contexts/FormRendererContext';
import PublicAcknowledgedAlert from './PublicAcknowledgedAlert';
import AcknowledgeButton from '../form/AcknowledgeButton';
import { DocumentResponse } from '../../models/Document';
import { ButtonSize } from '../shared/form-control/Button';
import { DistributionInfoForContactResponse } from '../../models/Distribution';
import DateUtils from '../../utils/DateUtils';
import { FormType } from '../../models/FormTypes';
import { FileUtils } from '../../utils/FileUtils';
import DocumentFileViewer from '../../pages/form/DocumentFileViewer';
import PublicFormBox from './PublicFormBox';

type Props = {
  distributionInfo: (DistributionInfoForContactResponse & { contactFullName: string }) | null;
};

const PublicAcknowledgementView: FC<Props> = (props) => {
  const { distributionInfo } = props;
  const { t } = useTranslation('distribution');
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const isDocumentFile = useMemo(
    () => distributionInfo?.clientForm?.type === FormType.Document && distributionInfo?.clientForm?.form.isSystem,
    [distributionInfo?.clientForm?.form.isSystem, distributionInfo?.clientForm?.type],
  );

  useEffect(() => {
    if (isDocumentFile) {
      FileUtils.getFileUrl((distributionInfo?.clientForm as DocumentResponse).fileId).then((url) => {
        setSelectedFile(url);
      });
    }
  }, [distributionInfo?.clientForm, isDocumentFile]);

  return distributionInfo ? (
    <>
      {distributionInfo.clientForm && (
        <>
          <FormRendererDesignContext.Provider
            value={{
              ...FormRendererDesignDefaults,
              sectionWrapper: PublicFormSectionWrapper,
              actionWrapper: PublicFormActionWrapper,
              sectionTitleProvider: PublicFormSectionTitle,
            }}
          >
            {!distributionInfo.clientForm.form.isSystem && (
              <FormRendererV2
                clientForm={distributionInfo.clientForm}
                initialMode={FormRendererMode.PreviewView}
                renderAllSteps
                featureToggles={{ disableModeToggle: true }}
                canEdit
                type={FormRendererType.Public}
              />
            )}
            {isDocumentFile && selectedFile && (
              <PublicFormBox>
                <DocumentFileViewer key={selectedFile} uri={selectedFile} type={(distributionInfo.clientForm as DocumentResponse).fileFormat} />
              </PublicFormBox>
            )}
          </FormRendererDesignContext.Provider>
          <div className="pb-8 pt-6">
            <AcknowledgeButton
              document={distributionInfo.clientForm as DocumentResponse}
              disabled={!!distributionInfo.errors?.acknowledgedOn}
              disabledTooltip={t('acknowledge-public.alert.title')}
              className="w-full hover:!scale-[1.01] hover:shadow-lg focus:!scale-[1.01]"
              containerClassName="w-full"
              size={ButtonSize.M}
              text={t('acknowledge-public.button')}
              requiresAuth={false}
              signerName={distributionInfo.contactFullName}
            />
          </div>
        </>
      )}
      {!!distributionInfo.errors?.acknowledgedOn && !distributionInfo.clientForm && (
        <PublicAcknowledgedAlert date={DateUtils.formatDateTime(new Date(distributionInfo.errors?.acknowledgedOn))} />
      )}
    </>
  ) : null;
};

export default PublicAcknowledgementView;
