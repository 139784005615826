import { useTranslation } from 'react-i18next';
import docSignedSvg from '../../assets/images/doc-signed.svg';
import UnClosableAlert from '../shared/modal/variants/UnClosableAlert';
import { FC } from 'react';

const PublicAcknowledgedAlert: FC<{ date: string }> = ({ date }) => {
  const { t } = useTranslation('distribution');
  return (
    <UnClosableAlert
      svg={docSignedSvg}
      title={t('acknowledge-public.alert.title')}
      description={t('acknowledge-public.alert.description', { date: date })}
    />
  );
};

export default PublicAcknowledgedAlert;
