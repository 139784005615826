import { FC } from 'react';
import { ModalContext } from '../../../../contexts/ModalContext';
import StandardModal from './StandardModal';

type Props = {
  svg?: string;
  title: string;
  description: string;
};

const UnClosableAlert: FC<Props> = (props) => {
  const { svg, title, description } = props;
  return (
    <ModalContext.Provider
      value={{
        open: true,
        modalWidth: 'w-full sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-[800px]',
      }}
    >
      <StandardModal tertiaryButtonIcon={null} uncloseable>
        <div className="flex flex-grow flex-col items-center px-4 py-4 text-center">
          {svg && <img src={svg} className="mb-6 object-cover" alt={title} />}
          <div className="px-4 md:px-10">
            <span className="sm:text-dpm-25 md:text-dpm-25 lg:text-dpm-32 text-dpm-25 text-primary-1 my-2">{title}</span>
            <p>{description}</p>
          </div>
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default UnClosableAlert;
