import { useTranslation } from 'react-i18next';
import docSignedSvg from '../../assets/images/expired-clock.svg';
import UnClosableAlert from '../shared/modal/variants/UnClosableAlert';

const PublicLinkExpiredAlert = () => {
  const { t } = useTranslation('distribution');
  return (
    <UnClosableAlert svg={docSignedSvg} title={t('acknowledge-public.expired.title')} description={t('acknowledge-public.expired.description')} />
  );
};

export default PublicLinkExpiredAlert;
