import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PublicFormHeader from '../../components/public-forms/PublicFormHeader';
import PublicFormNotFound from '../../components/public-forms/PublicFormNotFound';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { DocumentResponse } from '../../models/Document';
import FormUtils from '../../utils/FormUtils';
import PublicAcknowledgementView from '../../components/public-forms/PublicAcknowledgementView';
import ContentWrapper from '../../components/public-forms/ContentWrapper';
import { decodeJWT, extractFromJWT, isJWTValid } from '../../utils/JwtUtils';
import BaseService from '../../services/BaseService';
import PublicLinkExpiredAlert from '../../components/public-forms/PublicLinkExpiredAlert';
import { EventSystem } from '../../events/EventSystem';
import i18next from 'i18next';
import useFallbackLanguage from '../../hooks/useFallbackLanguage';
import DistributionService from '../../services/DistributionService';
import { DistributionInfoForContactResponse } from '../../models/Distribution';
import useUnauthenticate from '../../hooks/useUnauthenticate';
import { usePbkAuth } from '../../contexts/PbkAuthContext';

const PublicAcknowledgementPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [distributionInfo, setDistributionInfo] = useState<(DistributionInfoForContactResponse & { contactFullName: string }) | null>(null);
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = useMemo(() => query.get('token'), [query]);
  const tokenValid = useMemo(() => isJWTValid(token), [token]);
  const jwt = decodeJWT(token);
  const contactFullName = extractFromJWT(jwt, 'contact-full-name') as string;
  const linkExpired = useMemo(() => !tokenValid || !!distributionInfo?.errors?.expired, [distributionInfo?.errors?.expired, tokenValid]);
  const language = useFallbackLanguage();
  const logout = useUnauthenticate();

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const fetchDistributionInfo = () => {
      setLoading(true);
      i18next.changeLanguage(language).then(() => {
        BaseService.recreateHttpClient(token);
        DistributionService.getPublicDistribution()
          .then((res) => {
            setDistributionInfo({ ...res.data, contactFullName });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
    };

    if (linkExpired) {
      setLoading(false);
      return;
    }

    logout().then(fetchDistributionInfo);
  }, [token, linkExpired, contactFullName, logout, location.pathname, language]);

  useEffect(() => {
    const handler = () => {
      setDistributionInfo((prev) => {
        if (prev === null) {
          return prev;
        }
        return {
          ...prev,
          errors: {
            ...prev.errors,
            acknowledgedOn: new Date().toISOString(),
          },
        };
      });

      BaseService.clearToken();
    };
    EventSystem.listen('acknowledged', handler);
    return () => {
      EventSystem.stopListening('acknowledged', handler);
    };
  }, []);

  return (
    <div className="min-h-full">
      <PageLoader loading={loading}>
        {linkExpired ? (
          <PublicLinkExpiredAlert />
        ) : !distributionInfo ? (
          <PublicFormNotFound />
        ) : (
          <ContentWrapper>
            {distributionInfo.clientForm && (
              <PublicFormHeader
                title={FormUtils.getDocumentTitle(distributionInfo.clientForm as DocumentResponse)}
                description={(distributionInfo.clientForm as DocumentResponse).description}
              />
            )}
            <PublicAcknowledgementView distributionInfo={distributionInfo} />
          </ContentWrapper>
        )}
      </PageLoader>
    </div>
  );
};

export default PublicAcknowledgementPage;
